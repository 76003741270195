<template>
  <v-container class="quick-actions">
    <v-expansion-panels flat class="actions__tablet">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p>{{ $t('Home.quick_actions.quick_actions') }}</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-list class="tablet__buttons">
            <v-list-item-group>
              <v-list-item
                v-if="isPlanLevelFour || isPlanLevelThree"
                @click="redirect('sowing')"
              >
                <v-list-item-icon>
                  <font-awesome-icon color="#39af49" icon="seedling" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('Home.quick_actions.register_sowing') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="isPlanLevelTwo" @click="redirect('catalog')">
                <v-list-item-icon>
                  <font-awesome-icon color="#39af49" icon="list-ul" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('Home.quick_actions.see_catalog') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="isPlanLevelFour || isPlanLevelThree || isPlanLevelTwo"
                @click="redirect('fungicide')"
              >
                <v-list-item-icon>
                  <font-awesome-icon color="#39af49" icon="spray-can" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('Home.quick_actions.plan_fungicide') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="redirect('notes')">
                <v-list-item-icon>
                  <font-awesome-icon color="#39af49" icon="sticky-note" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('Home.quick_actions.create_note') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="openFieldsModal">
                <v-list-item-icon>
                  <font-awesome-icon color="#39af49" icon="vector-square" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('Home.quick_actions.see_fields') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="actions__desktop">
      <df-button
        v-if="isPlanLevelFour || isPlanLevelThree"
        class="desktop__button"
        color-icon="#5B6459"
        left-icon="seedling"
        variant="text-secondary"
        @click="redirect('sowing')"
      >
        {{ $t('Home.quick_actions.register_sowing') }}
      </df-button>
      <df-button
        v-if="isPlanLevelTwo"
        class="desktop__button"
        color-icon="#5B6459"
        left-icon="list-ul"
        variant="text-secondary"
        @click="redirect('catalog')"
      >
        {{ $t('Home.quick_actions.see_catalog') }}
      </df-button>
      <df-button
        v-if="isPlanLevelFour || isPlanLevelThree || isPlanLevelTwo"
        class="desktop__button"
        color-icon="#5B6459"
        left-icon="spray-can"
        variant="text-secondary"
        @click="redirect('fungicide')"
      >
        {{ $t('Home.quick_actions.plan_fungicide') }}
      </df-button>
      <df-button
        class="desktop__button"
        color-icon="#5B6459"
        left-icon="sticky-note"
        variant="text-secondary"
        @click="redirect('notes')"
      >
        {{ $t('Home.quick_actions.create_note') }}
      </df-button>
      <df-button
        class="desktop__button"
        color-icon="#5B6459"
        left-icon="vector-square"
        variant="text-secondary"
        @click="openFieldsModal"
      >
        {{ $t('Home.quick_actions.see_fields') }}
      </df-button>

      <modal-talhoes
        v-if="isOpenFieldsModal"
        :farm="currentFarm"
        @close-modal="closeFieldsModal"
      />
    </div>
  </v-container>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import ModalTalhoes from '@/pages/fazendas/ModalTalhoes.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'QuickActions',

  components: {
    DfButton,
    ModalTalhoes,
  },

  data() {
    return {
      isOpenFieldsModal: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarm']),
    ...mapGetters('user', [
      'isPlanLevelTwo',
      'isPlanLevelThree',
      'isPlanLevelFour',
    ]),
  },

  methods: {
    closeFieldsModal() {
      this.isOpenFieldsModal = false
    },
    openFieldsModal() {
      logEvent(events.homeModule.clickedButtonSeeAllFields)
      this.isOpenFieldsModal = true
    },
    redirect(name) {
      const routes = {
        catalog: {
          path: 'catalog',
          query: { category: 'variety', fazenda_id: this.currentFarm.id },
          event: 'clickedButtonSeeCatalog',
        },
        fungicide: {
          path: 'programas',
          query: { fazenda_id: this.currentFarm.id },
          event: 'clickedButtonPlanFungicide',
        },
        notes: {
          path: 'notes',
          query: { open_modal: true },
          event: 'clickedButtonCreateNote',
        },
        sowing: {
          path: 'sowings',
          query: {},
          event: 'clickedButtonSeeSowing',
        },
      }
      logEvent(events.homeModule[routes[name].event])
      this.$router.push({
        path: routes[name].path,
        query: routes[name].query,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.quick-actions {
  padding: 0px;

  @include d(m) {
    padding: 0px 12px !important;
  }
  .actions__tablet {
    display: none;

    @include d(t) {
      display: flex;
    }
    @include d(m) {
      display: flex;
    }
    .v-expansion-panel {
      border-radius: 8px;

      .v-expansion-panel-header {
        border: 1px solid #e6e9e6;
        border-radius: 8px;

        p {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          &:first-letter {
            text-transform: capitalize;
          }
        }

        &.v-expansion-panel-header--active {
          border-radius: 8px 8px 0px 0px;
          border-bottom: 1px solid #e6e9e6;
          min-height: 54px;
        }
      }
      .v-expansion-panel-content {
        position: absolute;
        border-right: 1px solid #e6e9e6;
        border-bottom: 1px solid #e6e9e6;
        border-left: 1px solid #e6e9e6;
        border-radius: 0px 0px 8px 8px;
        box-shadow: 0px 5px 4px -2px #aab2a9;
        background-color: #ffffff;
        width: 100%;
      }
      .tablet__buttons {
        padding: 16px 0px 0px 0px;

        & .v-list-item {
          min-height: 36px;
          padding: 0px 0px 0px 8px;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
        & .v-list-item__icon {
          margin: 10px 0px;
        }
        & .v-list-item__content {
          padding: 10px 0px;
          & .v-list-item__title {
            margin-left: 8px;
            color: #39af49;
            font-family: 'Rubik';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            &:first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
  .actions__desktop {
    display: flex;
    gap: 8px;

    @include d(t) {
      display: none;
    }
    @include d(m) {
      display: none;
    }
    .desktop__button {
      height: auto;
      padding: 14px 12px;
      border: 1px solid #e6e9e6;
    }
  }
}
::v-deep .v-btn__content {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  & p::first-letter {
    text-transform: capitalize;
  }
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 8px;
}
</style>
