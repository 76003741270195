<template>
  <header-no-farms v-if="hasNoFarms" />
  <permission-failed-message v-else-if="!hasPermission($p.farm.view)" />
  <div v-else>
    <home-header />
    <div class="home-component">
      <div class="df-flex-l">
        <section
          class="df-col-8 df-col-12-t df-col-12-m df-flex-xxl df-flex-col"
        >
          <quick-actions />
          <card-semeaduras
            v-if="semeaduras_toggle"
            :semeaduras="sowingsActive"
          />
          <section v-if="isPlanLevelFour || isPlanLevelThree">
            <item-list
              v-if="loadingSowings || sowingsActive.length"
              name="sowing"
              :items="sowingsActive"
              :loading="loadingSowings"
            />
            <without-item-card
              v-else
              name="sowing"
              text="WithoutItemCard.sowing.text"
              title="WithoutItemCard.sowing.title"
            />
          </section>
          <section class="d-flex flex-column">
            <item-list
              v-if="loadingPlots || plots.length"
              name="field"
              :items="plots"
              :loading="loadingPlots"
            />
            <without-item-card
              v-else
              name="field"
              text="WithoutItemCard.field.text"
              title="WithoutItemCard.field.title"
            />
          </section>
        </section>
        <section class="df-col-4 df-col-12-t df-col-12-m df-flex-l df-flex-col">
          <div class="df-flex-l df-flex-col">
            <card-weather />
            <quotation-card :key="$currentLanguage()" />
          </div>
          <communication-card v-if="cardCommunication && isBrazil" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { logEvent, events } from '@/services/analytics'
import { mapActions, mapGetters } from 'vuex'
import CardWeather from '@/components/Weather/CardWeather.vue'
import CardSemeaduras from '@/modules/home/components/CardSemeaduras.vue'
import CommunicationCard from '@/components/MarketQuotations/CommunicationCard.vue'
import HomeHeader from '@/modules/home/components/headers/HomeHeader.vue'
import HeaderNoFarms from '@/modules/home/components/headers/HeaderNoFarms.vue'
import ItemList from '@/modules/home/components/ItemList.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import QuickActions from '@/modules/home/components/QuickActions.vue'
import QuotationCard from '@/components/MarketQuotations/QuotationCard.vue'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'

export default {
  name: 'Home',

  mixins: [PermissionMixin],

  components: {
    CardWeather,
    CardSemeaduras,
    CommunicationCard,
    HomeHeader,
    HeaderNoFarms,
    ItemList,
    QuickActions,
    QuotationCard,
    WithoutItemCard,
  },

  data() {
    return {
      cardCommunication: this.$toggle('card_communication'),
      loadingPlots: false,
      loadingSowings: false,
      semeaduras_toggle: this.$toggle('semeaduras'),
    }
  },

  async created() {
    if (this.currentFarmId) {
      this.loadingPlots = true
      this.loadingSowings = true
      try {
        const fieldParams = {
          farmId: this.currentFarmId,
        }
        await Promise.allSettled([
          this.getPlots(fieldParams),
          this.loadAllSowings(['status', 'colheita']),
        ])
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingPlots = false
        this.loadingSowings = false
        logEvent(events.panels.visitedPage)
      }
    }
  },

  computed: {
    ...mapGetters('farms', ['hasNoFarms', 'currentFarmId', 'currentFarm']),
    ...mapGetters('plot', ['plots']),
    ...mapGetters('seasonsCrops', ['seasonsCrops']),
    ...mapGetters('semeaduras', ['allSowings']),
    ...mapGetters('user', ['isBrazil', 'isPlanLevelThree', 'isPlanLevelFour']),
    sowingsActive() {
      return this.allSowings.filter((semeadura) => !semeadura.colheita)
    },
  },

  methods: {
    ...mapActions('plot', ['getPlots']),
    ...mapActions('semeaduras', ['loadAllSowings']),
  },
}
</script>

<style lang="scss" scoped>
.home-component {
  max-width: 1472px;
  margin: 0px auto;
  padding: 0px 16px 40px 16px;

  @include d(m) {
    padding: 0px 8px 12px 8px;
  }
}
</style>
